import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { getPageImages } from '@/utils/resource';
import { formatRouterPath } from '@/utils/misc';
import { BrowserObject, detectBrowser } from '@/utils/browser';

export interface ProductItem {
  title?: string;
  desc?: string;
  features: { desc: string; subDesc: string; additionalInfo?: string }[];
  featureImgs: string[];
  btnText?: string;
  link?: string;
}

export interface Props {
  title?: string;
  items: ProductItem[];
  cls?: string;
}

const ProductCard: React.FC<ProductItem> = ({
  title,
  desc,
  features,
  featureImgs,
  btnText,
  link,
}) => {
  const router = useRouter();
  const [browserInfo, setBrowserInfo] = useState<BrowserObject | undefined>();

  useEffect(() => {
    setBrowserInfo(detectBrowser());
  }, []);

  return (
    <div>
      {title && (
        <h2 className={`${styles.name} support-line-break`}>{title}</h2>
      )}
      {desc && (
        <div
          className={cn(
            'font-lg',
            'text-gray',
            styles.desc,
            'support-line-break',
          )}
        >
          {desc}
        </div>
      )}
      <div className="row row-space-between">
        {features.map((feature, index, arr) => (
          <div
            key={feature.desc}
            className={cn(
              'col-12',
              `col-md-${12 / arr.length}`, // 和 PM 确认了, 可能会临时调整为 3 或 4 张图, 但暂时不会超过 4 张
              styles.featureItem,
            )}
          >
            <img
              className={styles.image}
              {...getPageImages(featureImgs[index])}
            />
            <div className={styles.feature}>
              <div className={cn(styles.featureDesc, 'support-line-break')}>
                {feature.desc}
              </div>
              <div
                className={cn(styles.featureSubDesc, 'support-line-break')}
                dangerouslySetInnerHTML={{ __html: feature.subDesc }}
              />
              {feature.additionalInfo && browserInfo?.isMobile && (
                <div
                  className={cn(styles.additionalInfo, 'support-line-break')}
                  dangerouslySetInnerHTML={{ __html: feature.additionalInfo }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      {features.map(
        (feature, index) =>
          feature.additionalInfo &&
          !browserInfo?.isMobile && (
            <div
              key={index}
              className={cn(styles.additionalInfo, 'support-line-break')}
              dangerouslySetInnerHTML={{ __html: feature.additionalInfo }}
            />
          ),
      )}
      {btnText && (
        <div
          className={cn('font-lg', styles.button)}
          onClick={() => {
            link && router.push(formatRouterPath(link));
          }}
        >
          {btnText}
        </div>
      )}
    </div>
  );
};

const ProductCardList: React.FC<Props> = ({ title, items, cls }) => {
  return (
    <div>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={cn('content', cls)}>
        {items.map((item, index) => (
          <ProductCard key={index} {...item} />
        ))}
      </div>
    </div>
  );
};

export default ProductCardList;
